(function e(t,n,r){function s(o,u){if(!n[o]){if(!t[o]){var a=typeof require=="function"&&require;if(!u&&a)return a(o,!0);if(i)return i(o,!0);var f=new Error("Cannot find module '"+o+"'");throw f.code="MODULE_NOT_FOUND",f}var l=n[o]={exports:{}};t[o][0].call(l.exports,function(e){var n=t[o][1][e];return s(n?n:e)},l,l.exports,e,t,n,r)}return n[o].exports}var i=typeof require=="function"&&require;for(var o=0;o<r.length;o++)s(r[o]);return s})({1:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = common;

var _header = require('./partials/_header');

var _header2 = _interopRequireDefault(_header);

var _footer = require('./partials/_footer');

var _footer2 = _interopRequireDefault(_footer);

var _controlEnterInput = require('./plug/control-enter-input');

var _openIOSKeyboard = require('./plug/openIOSKeyboard');

var _openIOSKeyboard2 = _interopRequireDefault(_openIOSKeyboard);

var _controlPopup = require('./partials/controlPopup');

var _controlPopup2 = _interopRequireDefault(_controlPopup);

var _validation = require('./plug/validation');

var _validation2 = _interopRequireDefault(_validation);

var _search = require('./plug/search');

var _search2 = _interopRequireDefault(_search);

var _detectTouch = require('./plug/detect-touch');

var _findParent = require('./plug/findParent');

var _findParent2 = _interopRequireDefault(_findParent);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function common() {
  (0, _header2.default)();

  (0, _footer2.default)();

  if (_detectTouch.isTouch) document.body.classList.add('touch-device');

  (0, _controlEnterInput.animTextInput)('input__field');

  (0, _openIOSKeyboard2.default)('.fix-ios-keyboard');

  (0, _controlPopup2.default)('.l-popups-section');

  var submitFunction = function submitFunction(e, form) {

    var token = form.querySelector('[name=_token]').value;

    var xhr = new XMLHttpRequest();

    var formData = new FormData(form);

    xhr.open('POST', '../send-mail');

    xhr.setRequestHeader('X-CSRF-TOKEN', token);

    xhr.send(formData);

    e.target.style.pointerEvents = 'none';

    xhr.onreadystatechange = function () {
      if (xhr.readyState != 4) {
        return;
      }

      if (xhr.status != 200) {
        console.log(xhr.status + ': ' + xhr.statusText);
      } else {
        document.querySelector('.popup__content-open').classList.add('submit-state');
      }
    };

    e.preventDefault();
  };

  var forms = document.querySelectorAll('.valid-form');

  [].map.call(forms, function (item) {
    (0, _validation2.default)(item, {
      wrapInputs: 'span',
      submitFunction: submitFunction
    });
  });

  (0, _search2.default)('[name=search]');
}

},{"./partials/_footer":3,"./partials/_header":4,"./partials/controlPopup":7,"./plug/control-enter-input":16,"./plug/detect-touch":18,"./plug/findParent":19,"./plug/openIOSKeyboard":23,"./plug/search":24,"./plug/validation":27}],2:[function(require,module,exports){
'use strict';

var _common = require('./common');

var _common2 = _interopRequireDefault(_common);

var _listenLoad = require('./plug/listenLoad');

var _listenLoad2 = _interopRequireDefault(_listenLoad);

var _animationOnScroll = require('./partials/animationOnScroll');

var _animationOnScroll2 = _interopRequireDefault(_animationOnScroll);

var _catalogBlock = require('./partials/main/_catalog-block');

var _catalogBlock2 = _interopRequireDefault(_catalogBlock);

var _triggerHoverEffectsForProduction = require('./partials/main/triggerHoverEffectsForProduction');

var _triggerHoverEffectsForProduction2 = _interopRequireDefault(_triggerHoverEffectsForProduction);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var heroSwiper = function heroSwiper() {
  var swipeBlock = document.getElementsByClassName('b-hero')[0];
  new Swiper(swipeBlock, {
    pagination: '.swiper-pagination',
    nextButton: '.swiper-button-next',
    prevButton: '.swiper-button-prev',
    speed: 800,
    autoplay: 5000,
    paginationClickable: true,
    loop: true
  });
};

(0, _common2.default)();

(0, _animationOnScroll2.default)('start', 'finish');

(0, _triggerHoverEffectsForProduction2.default)();

(0, _catalogBlock2.default)();

_listenLoad2.default.require(['https://cdnjs.cloudflare.com/ajax/libs/Swiper/3.4.2/js/swiper.min.js'], heroSwiper);

},{"./common":1,"./partials/animationOnScroll":6,"./partials/main/_catalog-block":13,"./partials/main/triggerHoverEffectsForProduction":14,"./plug/listenLoad":22}],3:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = controlFooter;

var _accordion = require('../plug/accordion');

var _accordion2 = _interopRequireDefault(_accordion);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function controlFooter() {
  var accords = document.getElementsByClassName('b-footer__top');

  var accArr = [];
  var accCreate = false;

  var controlAcc = function controlAcc() {
    if (window.innerWidth <= 768) {
      if (!accCreate) {
        [].map.call(accords, function (item) {
          var acc = new _accordion2.default(item, {
            defaultOpen: 0,
            time: 500,
            closePrevElem: true
          });

          acc.accordionStart();

          accArr.push(acc);
        });
        accCreate = true;
      }
    } else {
      if (accCreate) {
        [].map.call(accArr, function (item) {
          item.accordionStop();
        });

        accCreate = false;
      }
    }
  };

  controlAcc();

  window.addEventListener('resize', controlAcc);
}

},{"../plug/accordion":15}],4:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = controlHeader;

var _desktopMenu = require('../partials/header/desktopMenu');

var _desktopMenu2 = _interopRequireDefault(_desktopMenu);

var _mobileMenu = require('../partials/header/mobileMenu');

var _mobileMenu2 = _interopRequireDefault(_mobileMenu);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function controlHeader() {
  var pointToMobileMenu = 1023;
  var header = document.getElementsByClassName('l-header')[0];

  (0, _mobileMenu2.default)(header, pointToMobileMenu);

  (0, _desktopMenu2.default)(header, pointToMobileMenu);
}

},{"../partials/header/desktopMenu":9,"../partials/header/mobileMenu":10}],5:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = toggleHideBlockInCatalogueMenu;

var _toggleBlock = require('../plug/toggleBlock');

var _toggleBlock2 = _interopRequireDefault(_toggleBlock);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function toggleHideBlockInCatalogueMenu(cssSelector) {
  var switchBlocks = document.querySelectorAll(cssSelector);
  var time = 150;

  var callBack = function callBack(block) {
    var parent = block.parentElement;

    if (parent.classList.contains('b-sidebar__link')) {
      var list = parent.querySelector('.hidden-sublist');
      var btns = list.querySelectorAll('.action__toggle-sublist');

      [].map.call(btns, function (item) {
        var btn = item;
        btn.classList.remove('hidden-block__shown');

        var block = btn.nextElementSibling;
        block.style.height = '0';
        block.style.display = 'none';
        block.classList.remove('start-action');
        block.classList.remove('block-open');
      });
    }
  };

  var switchBlock = function switchBlock(e) {
    var target = e.target;
    var block = target.nextElementSibling;

    if (block.offsetHeight === 0) {
      target.classList.add('hidden-block__shown');
    } else {
      target.classList.remove('hidden-block__shown');
    }

    _toggleBlock2.default.slideToggleHeight(block, {
      // display: 'flex',
      display: 'block',
      time: time,
      callBack: {
        slideUp: callBack
      }
    });
  };

  [].map.call(switchBlocks, function (item) {
    item.onclick = switchBlock;
  });
}

},{"../plug/toggleBlock":25}],6:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = playAnimationOnScroll;

var _listenScroll = require('../plug/listen-scroll');

var _listenScroll2 = _interopRequireDefault(_listenScroll);

var _transitionEnd = require('../plug/transitionEnd');

var _transitionEnd2 = _interopRequireDefault(_transitionEnd);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function playAnimationOnScroll(classAnimEl, classAfterFinish) {
  var isAnimScroll = true;
  var startClass = classAnimEl || 'start';
  var finishClass = classAfterFinish || 'finish';

  (0, _listenScroll2.default)(controlAnim);

  function controlAnim(param) {
    if (isAnimScroll) {
      prepareToAnim();
      isAnimScroll = scrollAnim(startClass);
    }
  }

  function scrollAnim(startClass) {
    var el = document.querySelectorAll('.' + startClass);

    if (el.length === 0) return false;

    for (var i = 0, len = el.length; i < len; i++) {
      var posEl = el[i].getBoundingClientRect();

      if (posEl.top <= Math.max(window.innerHeight - el[i].offsetHeight / 2, window.innerHeight / 2)) {
        el[i].classList.remove(startClass);
        el[i].addEventListener(_transitionEnd2.default, listenAnim);
      }
    }

    function listenAnim() {
      this.classList.add(finishClass);
      this.removeEventListener(_transitionEnd2.default, listenAnim);
    }

    return true;
  }

  /*
   *   Подготовка элементов к анимации при скроле
   */
  function prepareToAnim() {
    var el = document.querySelectorAll('.' + startClass);

    if (el.length === 0) return;

    for (var i = 0, len = el.length; i < len; i++) {
      var posEl = el[i].getBoundingClientRect();

      if (posEl.top <= window.innerHeight && el[i].style.willChange === '') {
        willChangeSwitch(el[i], 'transition, opacity');
        el[i].addEventListener(_transitionEnd2.default, removeWillChange);
      }
    }
  }

  /*
   *   Создание слоя комнозиции с помощью will-change перед анимацией
   *   elem                        - анимируемый элемент
   *   prop                        - свойства, которые необходимо подготовить
   */
  function willChangeSwitch(elem, prop) {
    if (elem.length) {
      for (var i = 0; i < elem.length; i++) {
        elem[i].style.willChange = prop;
      }
    } else {
      elem.style.willChange = prop;
    }
  }

  /*
   *   Удаление слоя из композиции
   */
  function removeWillChange() {
    var self = this;
    willChangeSwitch(self, '');
    self.removeEventListener(_transitionEnd2.default, removeWillChange);
  }
}

},{"../plug/listen-scroll":21,"../plug/transitionEnd":26}],7:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = controlPopup;

var _hideScrollOnBody = require('../plug/hideScrollOnBody');

var _hideScrollOnBody2 = _interopRequireDefault(_hideScrollOnBody);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function controlPopup(cssSelector) {
  var popupOverlay = document.querySelector(cssSelector);
  var btnOpen = document.getElementsByClassName('action__open-popup');
  var btnClose = document.getElementsByClassName('action__close-popup');

  var isIOSDevice = !!navigator.userAgent.match(/iPhone|iPad|iPod/i);

  var handlerClickOut = function handlerClickOut(e) {
    var target = e.target;
    if (target === popupOverlay) {
      if (isIOSDevice) {
        document.removeEventListener('touchend', handlerClickOut);
        e.preventDefault();
      } else {
        document.removeEventListener('click', handlerClickOut);
      }
      togglePopup();
    }
  };

  var togglePopup = function togglePopup(name) {
    var current = void 0;

    if (name && typeof name === 'string') {
      current = popupOverlay.querySelector('.popup__' + name);

      if (isIOSDevice) {
        document.addEventListener('touchend', handlerClickOut, false);
      } else {
        document.addEventListener('click', handlerClickOut);
      }
    } else {
      current = popupOverlay.querySelector('.popup__content-open');
    }

    current.classList.toggle('popup__content-open');
    document.body.classList.toggle('popup__overlay-open');
    _hideScrollOnBody2.default.toggle([document.querySelector('.l-header'), document.querySelector('.l-social'), document.querySelector('#wh-widget-send-button')]);
  };

  [].map.call(btnOpen, function (item) {
    item.onclick = togglePopup.bind(null, item.getAttribute('data-popup'));
  });

  [].map.call(btnClose, function (item) {
    item.onclick = togglePopup;
  });
}

},{"../plug/hideScrollOnBody":20}],8:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = cutIcons;
function cutIcons() {
  var that = this;
  var iconsBlock = document.getElementsByClassName('cut-icon');
  var prevWidth = window.innerWidth;

  var init = function init() {
    var arr = [];
    [].map.call(iconsBlock, function (item) {
      var icons = item.getElementsByTagName('i');
      [].map.call(icons, function (item) {
        arr.push(item);
      });
    });
    return arr;
  };

  var icons = init();

  var calcState = function calcState(item, i) {
    setTimeout(function () {
      var parent = item.parentNode;
      var pos = item.getBoundingClientRect();
      var parentPos = parent.getBoundingClientRect();

      if (pos.left < parentPos.left || pos.right >= parentPos.right) {
        item.style.display = 'none';
      } else {
        item.style.display = '';
      }
    }, i * 50);
  };

  that.controlIcons = function () {
    [].map.call(icons, function (item) {
      item.style.display = '';
    });

    [].map.call(icons, function (item, i) {
      calcState(item, i);
    });
  };

  var handlerResize = function handlerResize() {
    if (prevWidth !== window.innerWidth) {
      that.controlIcons();
      prevWidth = window.innerWidth;
    }
  };

  that.controlIcons();

  window.addEventListener('resize', handlerResize);
}

},{}],9:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = desktop;

var _toggleBlockDesktop = require('./toggleBlockDesktop');

var _toggleBlockDesktop2 = _interopRequireDefault(_toggleBlockDesktop);

var _listenScroll = require('../../plug/listen-scroll');

var _listenScroll2 = _interopRequireDefault(_listenScroll);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function desktop(header, pointToMobileMenu) {
  var positionTransformHeader = 500;

  (0, _toggleBlockDesktop2.default)(header, pointToMobileMenu);

  var controlHeightHeader = function controlHeightHeader(param) {
    if (window.innerWidth > 1023) {
      if (window.innerHeight > 800) {
        if (param.scrollPage >= positionTransformHeader) {
          header.classList.add('small-header');
        } else {
          header.classList.remove('small-header');
        }
      } else {
        header.classList.add('small-header');
      }
    } else {
      header.classList.remove('small-header');
    }
  };

  (0, _listenScroll2.default)(controlHeightHeader);
};

},{"../../plug/listen-scroll":21,"./toggleBlockDesktop":11}],10:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = mobile;

var _toggleBlockMobile = require('./toggleBlockMobile');

var _toggleBlockMobile2 = _interopRequireDefault(_toggleBlockMobile);

var _hideScrollOnBody = require('../../plug/hideScrollOnBody');

var _hideScrollOnBody2 = _interopRequireDefault(_hideScrollOnBody);

var _detectMobile = require('../../plug/detect-mobile');

var _findParent = require('../../plug/findParent');

var _findParent2 = _interopRequireDefault(_findParent);

var _toggleBlocksInCatalogueMenu = require('../_toggleBlocksInCatalogueMenu');

var _toggleBlocksInCatalogueMenu2 = _interopRequireDefault(_toggleBlocksInCatalogueMenu);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function mobile(header, pointToMobileMenu) {
  var headerMobile = header.getElementsByClassName('header-state__mobile')[0];
  var burgers = headerMobile.getElementsByClassName('action__open-mobile-menu');
  var openSubmenu = headerMobile.getElementsByClassName('action__open-submenu');
  var closeSubmenu = headerMobile.getElementsByClassName('action__close-submenu');

  var listenClickOnBody = function listenClickOnBody(e) {
    var target = e.target;

    var parent = (0, _findParent2.default)(target, headerMobile);

    if (parent) {
      return false;
    }

    openMobileMenu(e);

    e.preventDefault();
  };

  var openMobileMenu = function openMobileMenu(e) {
    header.classList.toggle('open--mobile-menu');
    _hideScrollOnBody2.default.toggle([document.querySelector('.l-header'), document.querySelector('.l-social')]);

    if (header.classList.contains('open--mobile-menu')) {
      if (_detectMobile.isMobile.iOS()) {
        document.addEventListener('touchend', listenClickOnBody);
      } else {
        document.addEventListener('click', listenClickOnBody);
      }
    } else {
      header.classList.remove('open--mobile-submenu');

      if (_detectMobile.isMobile.iOS()) {
        document.removeEventListener('touchend', listenClickOnBody);
      } else {
        document.removeEventListener('click', listenClickOnBody);
      }
    }

    // e.stopPropagation();
  };

  var openMobileSubmenu = function openMobileSubmenu(e) {
    header.classList.add('open--mobile-submenu');

    e.stopPropagation();
  };

  var closeMobileSubmenu = function closeMobileSubmenu(e) {
    header.classList.remove('open--mobile-submenu');

    e.stopPropagation();
  };

  [].map.call(burgers, function (item) {
    item.addEventListener('click', openMobileMenu);
  });

  [].map.call(openSubmenu, function (item) {
    item.addEventListener('click', openMobileSubmenu);
  });

  [].map.call(closeSubmenu, function (item) {
    item.addEventListener('click', closeMobileSubmenu);
  });

  (0, _toggleBlockMobile2.default)(header, pointToMobileMenu);

  (0, _toggleBlocksInCatalogueMenu2.default)('.action__toggle-sublist');
};

},{"../../plug/detect-mobile":17,"../../plug/findParent":19,"../../plug/hideScrollOnBody":20,"../_toggleBlocksInCatalogueMenu":5,"./toggleBlockMobile":12}],11:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = toggleBlocks;

var _toggleBlock = require('../../plug/toggleBlock');

var _toggleBlock2 = _interopRequireDefault(_toggleBlock);

var _detectMobile = require('../../plug/detect-mobile');

var _findParent = require('../../plug/findParent');

var _findParent2 = _interopRequireDefault(_findParent);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function toggleBlocks(header, pointToMobileMenu) {
  var headerDesktop = header.getElementsByClassName('header-state__desktop')[0];
  var parent = headerDesktop.getElementsByClassName('b-header__right')[0];
  var overlayInner = parent.firstElementChild;

  var startPlug = false;
  var searchInFocus = false;
  var dealyTimer = null;

  var btnsToOpenHideArea = headerDesktop.getElementsByClassName('action__open-hide-area');
  var hideBlocks = headerDesktop.getElementsByClassName('b-header__hide-block');

  var time = 300;

  var removeFloatPosition = function removeFloatPosition() {
    if (header.classList.contains('float-panel')) {
      header.classList.remove('float-panel');

      [].map.call(hideBlocks, function (item) {
        item.style.display = '';
        item.style.width = '';
      });
    }
  };

  var callToggler = function callToggler(block, time, callBackFunc) {
    _toggleBlock2.default.slideToggleWidth(block, {
      // display: 'flex',
      display: 'block',
      time: time,
      callBack: {
        slideLeft: callBackFunc || null
      }
    });
  };

  var closeFloatPanel = function closeFloatPanel(e) {
    var target = e ? e.target : document.body;
    var onPanel = (0, _findParent2.default)(target, overlayInner);
    if (!onPanel && header.classList.contains('float-panel')) {
      [].map.call(hideBlocks, function (item) {
        if (item.classList.contains('block-open')) {
          callToggler(item, time, removeFloatPosition);

          if (item.firstElementChild.tagName === 'INPUT') {
            item.firstElementChild.blur();
          }

          [].map.call(header.querySelectorAll('.result-view'), function (item) {
            item.innerHTML = '';
          });
          [].map.call(header.querySelectorAll('[name=search]'), function (item) {
            item.value = '';
          });

          if (_detectMobile.isMobile.iOS()) {
            document.removeEventListener('touchend', closeFloatPanel);
          } else {
            document.removeEventListener('click', closeFloatPanel);
          }
          return false;
        }
      });
    }
  };

  var toggleHideArea = function toggleHideArea(e) {
    var btn = e.target.classList.contains('action__open-hide-area') ? e.target : e.target.querySelector('.action__open-hide-area');
    var oneBlockOpen = false;

    var hanlerToAction = function hanlerToAction() {
      if (e.type !== 'mouseenter' && e.type !== 'mouseleave') {
        clearTimeout(dealyTimer);
        dealyTimer = null;
      }

      [].map.call(hideBlocks, function (item) {
        if (item.previousElementSibling !== btn && item.classList.contains('block-open')) {
          callToggler(item, time * 0.75);

          callToggler(btn.nextElementSibling, time * 0.75);

          oneBlockOpen = true;

          return false;
        }
      });

      if (!oneBlockOpen) {
        if (!header.classList.contains('float-panel')) {
          header.classList.add('float-panel');

          if (_detectMobile.isMobile.iOS()) {
            document.addEventListener('touchend', closeFloatPanel);
          } else {
            document.addEventListener('click', closeFloatPanel);
          }
        }
        if (e.type !== 'mouseleave') {
          callToggler(btn.nextElementSibling, time, removeFloatPosition);
        } else {
          if (e.type === 'mouseleave' && !searchInFocus) {
            callToggler(btn.nextElementSibling, time, removeFloatPosition);
          }
        }
      }
    };

    if (e.type === 'mouseenter') {
      clearTimeout(dealyTimer);

      dealyTimer = setTimeout(function () {
        hanlerToAction();
        dealyTimer = null;
      }, time);
    } else {

      if (e.type !== 'mouseleave') {

        clearTimeout(dealyTimer);
        dealyTimer = null;

        hanlerToAction();
      } else {

        clearTimeout(dealyTimer);

        if ((0, _findParent2.default)(e.toElement, e.target.parentNode.parentNode)) {
          dealyTimer = setTimeout(function () {

            if (!searchInFocus) {
              [].map.call(hideBlocks, function (item) {
                if (item.classList.contains('block-open')) {
                  _toggleBlock2.default.slideLeft(item, {
                    display: 'block',
                    time: time * 0.75,
                    callBack: {
                      slideLeft: removeFloatPosition
                    }
                  });

                  oneBlockOpen = false;

                  return false;
                }
              });
            }

            dealyTimer = null;
          }, time * 2);
        }
      }
    }
  };

  var inFocusSearch = function inFocusSearch() {
    clearTimeout(dealyTimer);
    dealyTimer = null;
    searchInFocus = true;
  };

  var offFocusSearch = function offFocusSearch() {
    searchInFocus = false;
  };

  var initPlug = function initPlug() {
    if (window.innerWidth > pointToMobileMenu) {
      if (!startPlug) {
        [].map.call(btnsToOpenHideArea, function (item) {
          item.addEventListener('click', toggleHideArea);
          item.parentNode.addEventListener('mouseenter', toggleHideArea);
          item.parentNode.addEventListener('mouseleave', toggleHideArea);
        });
        headerDesktop.querySelector('.b-header__hide-area[type=search]').addEventListener('focus', inFocusSearch);
        headerDesktop.querySelector('.b-header__hide-area[type=search]').addEventListener('blur', offFocusSearch);
        startPlug = true;
      }
    } else {
      if (startPlug) {
        startPlug = false;
        closeFloatPanel();
        removeFloatPosition();
        [].map.call(btnsToOpenHideArea, function (item) {
          item.removeEventListener('click', toggleHideArea);
          headerDesktop.querySelector('.b-header__hide-area[type=search]').removeEventListener('focus', inFocusSearch);
          headerDesktop.querySelector('.b-header__hide-area[type=search]').removeEventListener('blur', offFocusSearch);
        });
      }
    }
  };

  initPlug();

  window.addEventListener('resize', initPlug);
};

},{"../../plug/detect-mobile":17,"../../plug/findParent":19,"../../plug/toggleBlock":25}],12:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = toggleBlocks;

var _detectMobile = require('../../plug/detect-mobile');

var _findParent = require('../../plug/findParent');

var _findParent2 = _interopRequireDefault(_findParent);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function toggleBlocks(header, pointToMobileMenu) {
  var headerMobile = header.getElementsByClassName('header-state__mobile')[0];

  var startPlug = false;

  var btnsToOpenHideArea = headerMobile.getElementsByClassName('action__open-hide-area');
  var hideBlocks = headerMobile.getElementsByClassName('b-header__hide-block');
  var resultView = headerMobile.getElementsByClassName('result-view');
  var input = headerMobile.querySelector('input.b-header__search');

  var closeFloatPanel = function closeFloatPanel(e) {
    var target = e ? e.target : document.body;
    var onPanel = (0, _findParent2.default)(target, hideBlocks[0]);
    var onList = (0, _findParent2.default)(target, resultView[0]);

    if (!onPanel && !onList && header.classList.contains('float-panel')) {
      [].map.call(hideBlocks, function (item) {
        item.firstElementChild.blur();
        header.classList.remove('float-panel');

        [].map.call(header.querySelectorAll('.result-view'), function (item) {
          item.innerHTML = '';
        });
        [].map.call(header.querySelectorAll('[name=search]'), function (item) {
          item.value = '';
        });
      });
      if (_detectMobile.isMobile.iOS()) {
        document.removeEventListener('touchend', closeFloatPanel);
      } else {
        document.removeEventListener('click', closeFloatPanel);
      }
      return false;
    }
  };

  var toggleHideArea = function toggleHideArea(e) {
    if (!header.classList.contains('float-panel')) {
      header.classList.add('float-panel');

      input.focus();

      if (_detectMobile.isMobile.iOS()) {
        document.addEventListener('touchend', closeFloatPanel);
      } else {
        document.addEventListener('click', closeFloatPanel);
      }
    } else {
      header.classList.remove('float-panel');
    }

    e.stopPropagation();
  };

  var initPlug = function initPlug() {
    if (window.innerWidth <= pointToMobileMenu) {
      if (!startPlug) {
        [].map.call(btnsToOpenHideArea, function (item) {
          item.addEventListener('click', toggleHideArea);
        });
        startPlug = true;
      }
    } else {
      if (startPlug) {
        startPlug = false;
        closeFloatPanel();
        [].map.call(btnsToOpenHideArea, function (item) {
          item.removeEventListener('click', toggleHideArea);
        });
      }
    }
  };

  initPlug();

  window.addEventListener('resize', initPlug);
};

},{"../../plug/detect-mobile":17,"../../plug/findParent":19}],13:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = catalogBlock;

var _cutIcons = require('../cutIcons');

var _cutIcons2 = _interopRequireDefault(_cutIcons);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var catalogDarken = function catalogDarken() {
  var btn = document.getElementsByClassName('b-catalog__button')[0];

  btn.onmouseenter = function () {
    btn.parentNode.classList.add('element-under-hover');
  };

  btn.onmouseleave = function () {
    btn.parentNode.classList.remove('element-under-hover');
  };
};

function catalogBlock() {
  new _cutIcons2.default();

  catalogDarken();
}

},{"../cutIcons":8}],14:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = triggerHoverEffectsForProduction;

var _detectMobile = require('../../plug/detect-mobile');

var _findParent = require('../../plug/findParent');

var _findParent2 = _interopRequireDefault(_findParent);

var _transitionEnd = require('../../plug/transitionEnd');

var _transitionEnd2 = _interopRequireDefault(_transitionEnd);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function triggerHoverEffectsForProduction() {
  var block = document.getElementsByClassName('b-prod')[0];
  var currentBlock = void 0;
  var currentHiddenBlock = void 0;
  var cssHiddenBlock = '.b-prod-item__hide';

  var hasDocListen = false;

  var deleteHover = function deleteHover(e) {
    var target = (0, _findParent2.default)(e.target, block);

    if (!target && hasDocListen) {
      hasDocListen = false;
      document.removeEventListener('touchend', deleteHover);

      currentHiddenBlock.style.pointerEvents = '';
      currentBlock = null;
    }
  };

  var handlerFinishAnim = function handlerFinishAnim() {
    setTimeout(function () {
      currentHiddenBlock.style.pointerEvents = 'auto';
    }, 500);
    this.removeEventListener(_transitionEnd2.default, handlerFinishAnim);
  };

  var triggerHover = function triggerHover(e) {
    var temp = (0, _findParent2.default)(e.target, '.b-prod-item');

    if (currentBlock && temp !== currentBlock) {
      currentHiddenBlock.style.pointerEvents = '';
    }

    if (temp) {
      currentBlock = temp;
      currentHiddenBlock = currentBlock.querySelector(cssHiddenBlock);

      currentBlock.addEventListener(_transitionEnd2.default, handlerFinishAnim);
    }

    if (!hasDocListen) {
      document.addEventListener('touchend', deleteHover);
      hasDocListen = true;
    }
  };

  if (_detectMobile.isMobile.iOS()) {
    block.addEventListener('touchend', triggerHover);

    block.classList.add('mobile-iOS');
  }
}

},{"../../plug/detect-mobile":17,"../../plug/findParent":19,"../../plug/transitionEnd":26}],15:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = Accordion;
/**
 *  Create accordion
 * @param elem  - element wrap
 * @param settingsInput - object settings
 * @constructor
 */
function Accordion(elem, settingsInput) {
  var that = this;

  var defaultWidth = window.innerWidth;

  that.accordionWrap = elem;
  that.items = elem.getElementsByClassName('acc-item');
  that.button = elem.getElementsByClassName('acc-button-element');
  that.hidden = elem.getElementsByClassName('acc-hidden-element');

  var defaultSettings = {
    time: 200,
    defaultOpen: null,
    closePrevElem: false,
    callBackUp: null,
    callBackDown: null
  };

  that.settings = extendObject(defaultSettings, settingsInput);

  that.accordionStart = function () {
    for (var i = 0, len = this.button.length; i < len; i++) {
      this.button[i].addEventListener('click', handlerClick);
      this.items[i].classList.add('accordion-item');

      this.hidden[i].style.height = 0;
      this.hidden[i].style.overflow = 'hidden';
      this.hidden[i].style.display = 'none';
    }

    window.addEventListener('resize', resizeAccordion.bind(that));

    if (that.settings.defaultOpen !== null) {
      var index = that.settings.defaultOpen;
      that.button[index].click();
    }

    this.accordionWrap.classList.add('start-acc');
  };

  function handlerClick(e) {
    clickAccordion.call(this, e);
    e.preventDefault();
    e.stopPropagation();
  }

  function clickAccordion(e) {
    var self = this;
    var time = that.settings.time;
    var bringStarted = Date.now();
    var durationUp = time;
    var durationDown = time;
    var bringEndsDown = bringStarted + durationDown;
    var bringEndsUp = bringStarted + durationUp;

    var upElement = void 0;
    var prevElem = void 0;

    if (that.settings.closePrevElem) {
      prevElem = that.accordionWrap.querySelector('.acc-active');
      upElement = prevElem && prevElem !== self ? prevElem : prevElem && prevElem === self ? self : null;
      if (prevElem) {
        prevElem.classList.remove('acc-active');
      }
    } else {
      upElement = self.classList.contains('acc-active') ? self : null;
    }

    if (prevElem !== self) {
      self.classList.toggle('acc-active');
    }
    if (upElement) {
      window.requestAnimationFrame(bringSlideUp.bind(null, upElement, that.settings.callBackUp));
    } else {
      window.requestAnimationFrame(bringSlideDown.bind(null, self, that.settings.callBackDown));
    }

    function bringSlideUp(buttonBlock, callBack) {
      var panel = buttonBlock.nextElementSibling;

      var dt = void 0,
          t = void 0,
          temp = void 0;

      t = Date.now();

      if (t <= bringEndsUp) {
        dt = t - bringStarted;
        temp = EasingFunctions.easeInQuart(dt / durationUp);

        panel.style.height = panel.scrollHeight - Math.floor(panel.scrollHeight * temp) + 'px';

        window.requestAnimationFrame(bringSlideUp.bind(null, buttonBlock, callBack));
      } else {
        panel.style.height = '0px';
        panel.style.display = 'none';

        if (typeof callBack === 'function') {
          callBack();
        }

        if (that.settings.closePrevElem) {
          bringStarted = Date.now();
          bringEndsDown = bringStarted + durationDown;

          var nextOpen = that.accordionWrap.querySelector('.acc-active');
          if (nextOpen) {
            window.requestAnimationFrame(bringSlideDown.bind(null, nextOpen, that.settings.callBackDown));
          }
        }
      }
    }

    function bringSlideDown(buttonBlock, callBack) {
      var panel = buttonBlock.nextElementSibling;

      panel.style.display = '';

      var dt = void 0,
          t = void 0,
          temp = void 0;

      t = Date.now();

      if (t <= bringEndsDown) {
        dt = t - bringStarted;
        temp = EasingFunctions.easeOutQuart(dt / durationDown);

        panel.style.height = Math.floor(panel.scrollHeight * temp) + 'px';

        window.requestAnimationFrame(bringSlideDown.bind(null, buttonBlock, callBack));
      } else {
        panel.style.height = Math.floor(panel.scrollHeight) + 'px';

        if (typeof callBack === 'function') {
          callBack();
        }
      }
    }
  }

  that.accordionStop = function () {
    for (var i = 0, len = this.button.length; i < len; i++) {
      this.button[i].removeEventListener('click', handlerClick);

      this.items[i].classList.remove('accordion-item');

      this.hidden[i].style.display = '';
      this.hidden[i].style.height = '';
      this.hidden[i].style.padding = '';
      this.hidden[i].style.overflow = '';
    }

    if (this.accordionWrap.querySelector('.active')) {
      this.accordionWrap.querySelector('.active').classList.remove('active');
    }

    this.accordionWrap.classList.remove('start-acc');

    window.removeEventListener('resize', resizeAccordion);
  };

  function resizeAccordion(e) {
    setTimeout(function () {
      if (defaultWidth === window.innerWidth) {
        return false;
      } else {
        var actives = elem.querySelectorAll('.acc-active');

        for (var i = 0, len = actives.length; i < len; i++) {
          var hidden = actives[i].nextElementSibling;
          hidden.style.height = 0;
          hidden.style.height = hidden.scrollHeight + 'px';
        }

        defaultWidth = window.innerWidth;
      }
    }, 150);
  }

  var EasingFunctions = {
    // no easing, no acceleration
    linear: function linear(t) {
      return t;
    },
    // accelerating from zero velocity
    easeInQuad: function easeInQuad(t) {
      return t * t;
    },
    // decelerating to zero velocity
    easeOutQuad: function easeOutQuad(t) {
      return t * (2 - t);
    },
    // acceleration until halfway, then deceleration
    easeInOutQuad: function easeInOutQuad(t) {
      return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
    },
    // accelerating from zero velocity
    easeInCubic: function easeInCubic(t) {
      return t * t * t;
    },
    // decelerating to zero velocity
    easeOutCubic: function easeOutCubic(t) {
      return --t * t * t + 1;
    },
    // acceleration until halfway, then deceleration
    easeInOutCubic: function easeInOutCubic(t) {
      return t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1;
    },
    // accelerating from zero velocity
    easeInQuart: function easeInQuart(t) {
      return t * t * t * t;
    },
    // decelerating to zero velocity
    easeOutQuart: function easeOutQuart(t) {
      return 1 - --t * t * t * t;
    },
    // acceleration until halfway, then deceleration
    easeInOutQuart: function easeInOutQuart(t) {
      return t < 0.5 ? 8 * t * t * t * t : 1 - 8 * --t * t * t * t;
    },
    // accelerating from zero velocity
    easeInQuint: function easeInQuint(t) {
      return t * t * t * t * t;
    },
    // decelerating to zero velocity
    easeOutQuint: function easeOutQuint(t) {
      return 1 + --t * t * t * t * t;
    },
    // acceleration until halfway, then deceleration
    easeInOutQuint: function easeInOutQuint(t) {
      return t < 0.5 ? 16 * t * t * t * t * t : 1 + 16 * --t * t * t * t * t;
    }
  };

  function extendObject(a, b) {
    var c = {};
    var key = void 0;
    for (key in a) {
      if (a.hasOwnProperty(key)) {
        c[key] = key in b ? b[key] : a[key];
      }
    }
    return c;
  }
}

},{}],16:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.animTextInput = animTextInput;
exports.togglePass = togglePass;
exports.controlHeightTextArea = controlHeightTextArea;

var _findParent = require('../plug/findParent');

var _findParent2 = _interopRequireDefault(_findParent);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

/**
 *    Listen enter inputs and top-bottom placeholder
 *    className                  - wrap of inputs input
 */
function animTextInput(className) {
  var input = document.getElementsByClassName(className);
  var i = void 0,
      len = void 0;

  controlHeightTextArea();

  // Styles inputs
  for (i = 0, len = input.length; i < len; i++) {
    input[i].addEventListener('focus', handlerClickInput);
  }

  function handlerClickInput(e) {
    var noBlurAction = this.getAttribute('data-no-blur-after');
    var parent = (0, _findParent2.default)(this, '.input');
    parent.classList.add('input--filled');
    if (this.value === '' && this.parentNode.querySelector('.input__label-content')) {
      this.parentNode.querySelector('.input__label-content').style.opacity = '';
    }
    if (!noBlurAction || window.innerWidth > noBlurAction) {
      this.addEventListener('blur', handlerBlurInput);
    }
  }

  function handlerBlurInput() {
    var _this = this;

    setTimeout(function () {
      var isValueEmpty = _this.value === '';
      if (isValueEmpty) {
        var parent = (0, _findParent2.default)(_this, '.input');
        parent.classList.remove('input--filled');
        _this.value = '';
      }
      _this.removeEventListener('blur', handlerBlurInput);
    }, 125);
  }
}

/**
 * Show/hide password in input
 * @param button - button to show/hide password
 */
function togglePass(button) {
  var showPass = document.getElementsByClassName(button);

  for (var i = 0, len = showPass.length; i < len; i++) {
    showPass[i].addEventListener('click', toggleInputsPass);
  }

  function toggleInputsPass() {
    var self = this;
    var passInput = self.parentNode.querySelector('input');

    var tag = passInput.getAttribute('type');

    switch (tag) {
      case 'password':
        passInput.setAttribute('type', 'text');
        this.classList.add('show-password');
        break;
      case 'text':
        passInput.setAttribute('type', 'password');
        this.classList.remove('show-password');
        break;
    }
  }
}

/**
 * Height of textarea
 */
function controlHeightTextArea() {
  var textarea = document.querySelectorAll('textarea[data-autoresize]');

  var heightInput = [];
  var defaultHeight = [];

  var resizeTextarea = function resizeTextarea(index) {
    var self = this;

    if (self.scrollHeight > defaultHeight[index]) {
      if (heightInput[index] !== null && self.scrollHeight > heightInput[index]) {
        self.parentNode.style.transition = 'height 0.2s ease';
        self.parentNode.style.height = self.scrollHeight + 18 + 'px';
      }
      self.parentNode.style.lineHeight = '1.75em';
      self.style.height = self.scrollHeight + 'px';
    }
  };

  for (var i = 0, len = textarea.length; i < len; i++) {
    defaultHeight[i] = textarea[i].offsetHeight;
    heightInput[i] = textarea[i].parentNode.classList.contains('input') ? textarea[i].parentNode.offsetHeight : null;
    textarea[i].addEventListener('keyup', resizeTextarea.bind(textarea[i], i));
  }
}

},{"../plug/findParent":19}],17:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var isMobile = exports.isMobile = {
  Android: function Android() {
    return !!navigator.userAgent.match(/Android/i);
  },
  BlackBerry: function BlackBerry() {
    return !!navigator.userAgent.match(/BlackBerry/i);
  },
  iOS: function iOS() {
    return !!navigator.userAgent.match(/iPhone|iPad|iPod/i);
  },
  Opera: function Opera() {
    return !!navigator.userAgent.match(/Opera Mini/i);
  },
  Windows: function Windows() {
    return !!(navigator.userAgent.match(/IEMobile/i) || navigator.userAgent.match(/WPDesktop/i));
  },
  any: function any() {
    return isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows();
  }
};

},{}],18:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
var isTouchDevice = function isTouchDevice() {
  return 'ontouchstart' in window || navigator.maxTouchPoints;
};

var isTouch = exports.isTouch = isTouchDevice();

},{}],19:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = findParent;
function findParent(node, selector) {
  var condition = typeof selector === 'string' ? function (item) {
    return item.matches(selector);
  } : function (item) {
    return item === selector;
  };

  var notFind = true;
  while (node.parentNode) {
    if (condition(node)) {
      notFind = false;
      break;
    }
    node = node.parentNode;
  }
  return notFind ? false : node;
}

},{}],20:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
function ToggleScroll() {
  var self = this;
  var otherElementsToPadding = [];

  var paddingsSetToOther = function paddingsSetToOther(array, padding) {
    var arr = array || null;
    otherElementsToPadding = arr;

    if (arr && arr.length > 0) {
      [].map.call(arr, function (item) {
        if (item) {
          item.style.paddingRight = padding + 'px';
        }
      });
    }
  };

  var paddingsResetToOther = function paddingsResetToOther() {
    var array = otherElementsToPadding;
    if (array && array.length > 0) {
      [].map.call(array, function (item) {
        if (item) {
          item.style.paddingRight = '';
        }
      });
    }
  };

  self.off = function (arrayElemsToResize) {
    var padding = window.innerWidth - document.documentElement.clientWidth;
    document.body.classList.add('no-scroll');
    document.body.style.paddingRight = padding + 'px';
    paddingsSetToOther(arrayElemsToResize, padding);
  };

  self.on = function () {
    document.body.classList.remove('no-scroll');
    document.body.style.paddingRight = '';
    paddingsResetToOther();
  };

  self.toggle = function (arrayElemsToResize) {
    if (document.body.classList.contains('no-scroll')) {
      self.on();
    } else {
      self.off(arrayElemsToResize);
    }
  };
}

exports.default = new ToggleScroll();

},{}],21:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = controlScroll;
function controlScroll(functionToCall, set) {
  var ticking = false;
  var prevValueV = 0;
  var prevValueH = 0;
  var param = {
    direct: {}
  };

  var defaultSet = {
    pointNotWork: 0
  };

  var settings = extendObject(defaultSet, set || {});

  var directScrollV = function directScrollV(scrollTop) {
    var result = scrollTop - prevValueV > 0 ? 'down' : scrollTop - prevValueV < 0 ? 'up' : 'no-scroll';
    prevValueV = scrollTop;
    return result;
  };

  var directScrollH = function directScrollH(scrollLeft) {
    var result = scrollLeft - prevValueH > 0 ? 'right' : scrollLeft - prevValueH < 0 ? 'left' : 'no-scroll';
    prevValueH = scrollLeft;
    return result;
  };

  var handlerScroll = function handlerScroll(e) {
    param.scrollPage = window.pageYOffset || document.documentElement.scrollTop;
    param.scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
    param.direct.vertical = directScrollV(param.scrollPage);
    param.direct.horizontal = directScrollH(param.scrollLeft);
    if (!ticking) {
      window.requestAnimationFrame(function () {
        if (typeof functionToCall === 'function') {
          functionToCall(param);
        }
        ticking = false;
      });
    }
    ticking = true;
  };

  var handlerResize = function handlerResize(e) {
    if (window.innerWidth > settings.pointNotWork) {
      window.addEventListener('scroll', handlerScroll, false);
      handlerScroll();
    } else {
      window.removeEventListener('scroll', handlerScroll);
    }
  };

  (function () {
    var attachEvent = document.attachEvent;
    var isIE = navigator.userAgent.match(/Trident/);
    var requestFrame = function () {
      var raf = window.requestAnimationFrame || window.mozRequestAnimationFrame || window.webkitRequestAnimationFrame || function (fn) {
        return window.setTimeout(fn, 20);
      };
      return function (fn) {
        return raf(fn);
      };
    }();

    var cancelFrame = function () {
      var cancel = window.cancelAnimationFrame || window.mozCancelAnimationFrame || window.webkitCancelAnimationFrame || window.clearTimeout;
      return function (id) {
        return cancel(id);
      };
    }();

    function resizeListener(e) {
      var win = e.target || e.srcElement;
      if (win.__resizeRAF__) cancelFrame(win.__resizeRAF__);
      win.__resizeRAF__ = requestFrame(function () {
        var trigger = win.__resizeTrigger__;
        trigger.__resizeListeners__.forEach(function (fn) {
          fn.call(trigger, e);
        });
      });
    }

    function objectLoad(e) {
      this.contentDocument.defaultView.__resizeTrigger__ = this.__resizeElement__;
      this.contentDocument.defaultView.addEventListener('resize', resizeListener);
    }

    window.addResizeListener = function (element, fn) {
      if (!element.__resizeListeners__) {
        element.__resizeListeners__ = [];
        if (attachEvent) {
          element.__resizeTrigger__ = element;
          element.attachEvent('onresize', resizeListener);
        } else {
          if (getComputedStyle(element).position === 'static') element.style.position = 'relative';
          var obj = element.__resizeTrigger__ = document.createElement('object');
          obj.setAttribute('style', 'display: block; position: absolute; top: 0; left: 0; height: 100%; width: 100%; overflow: hidden; pointer-events: none; z-index: -1;');
          obj.__resizeElement__ = element;
          obj.onload = objectLoad;
          obj.type = 'text/html';
          if (isIE) element.appendChild(obj);
          obj.data = 'about:blank';
          obj.setAttribute('aria-label', 'hidden block');
          if (!isIE) element.appendChild(obj);
        }
      }
      element.__resizeListeners__.push(fn);
    };

    window.removeResizeListener = function (element, fn) {
      element.__resizeListeners__.splice(element.__resizeListeners__.indexOf(fn), 1);
      if (!element.__resizeListeners__.length) {
        if (attachEvent) element.detachEvent('onresize', resizeListener);else {
          element.__resizeTrigger__.contentDocument.defaultView.removeEventListener('resize', resizeListener);
          element.__resizeTrigger__ = !element.removeChild(element.__resizeTrigger__);
        }
      }
    };
  })();
  window.addResizeListener(document.querySelector('.wrap'), handlerResize);

  window.addEventListener('resize', handlerResize, false);

  window.addEventListener('load', handlerResize);

  function extendObject(a, b) {
    var c = {};
    var key = void 0;
    for (key in a) {
      if (a.hasOwnProperty(key)) {
        c[key] = key in b ? b[key] : a[key];
      }
    }
    return c;
  }
}

},{}],22:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
var Loader = function Loader() {};

Loader.prototype = {
  require: function require(scripts, callback) {
    this.loadCount = 0;
    this.totalRequired = scripts.length;
    this.callback = callback;

    for (var i = 0; i < scripts.length; i++) {
      this.writeScript(scripts[i]);
    }
  },
  loaded: function loaded(evt) {
    this.loadCount++;

    if (this.loadCount === this.totalRequired && typeof this.callback === 'function') this.callback.call();
  },
  writeScript: function writeScript(src) {
    var self = this;
    var s = document.createElement('script');
    s.type = 'text/javascript';
    s.async = true;
    s.src = src;
    s.addEventListener('load', function (e) {
      self.loaded(e);
    }, false);
    var head = document.getElementsByTagName('head')[0];
    head.appendChild(s);
  }
};

exports.default = new Loader();

},{}],23:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = controlKeyboardIOS;

var _detectMobile = require('./detect-mobile');

var handlerToggleKeyboard = {
  onOpen: function onOpen() {
    var offset = document.body.scrollTop;
    document.body.style.top = offset * -1 + 'px';
    document.body.classList.add('modal--opened');
    document.body.style.position = 'fixed';
    document.body.style.left = '0';
    document.body.style.right = '0';
  },
  onClose: function onClose() {
    var offset = parseInt(document.body.style.top, 10);
    document.body.classList.remove('modal--opened');
    document.body.style.position = '';
    document.body.style.left = '';
    document.body.style.right = '';
    document.body.scrollTop = offset * -1;
  }
};

function controlKeyboardIOS(selector) {
  if (_detectMobile.isMobile.iOS()) {
    var controls = typeof selector === 'string' ? document.querySelectorAll(selector) : selector;

    [].map.call(controls, function (item) {
      item.addEventListener('focus', handlerToggleKeyboard.onOpen);
      item.addEventListener('blur', handlerToggleKeyboard.onClose);
    });
  }
}

},{"./detect-mobile":17}],24:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = search;
function search(selector) {
  var inputs = document.querySelectorAll(selector);
  var timer = void 0;

  var submit = function submit(input) {

    var token = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

    var xhr = new XMLHttpRequest();

    var formData = new FormData();

    formData.append('search_key', input.value);

    var view = input.parentNode.nextElementSibling;

    var location = '/' + document.documentElement.getAttribute('lang');

    xhr.open('POST', location + '/searching');

    xhr.setRequestHeader('X-CSRF-TOKEN', token);

    xhr.send(formData);

    xhr.onreadystatechange = function (data) {

      if (xhr.readyState != 4) {
        return;
      }

      if (xhr.status != 200) {
        console.log(xhr.status + ': ' + xhr.statusText);
      } else {
        view.innerHTML = xhr.responseText;
      }
    };
  };

  var handlerUp = function handlerUp(e) {
    var input = e.target;

    timer = setTimeout(function () {
      submit(input);
    }, 500);
  };

  var handlerDown = function handlerDown() {
    clearTimeout(timer);
  };

  [].map.call(inputs, function (item) {
    item.addEventListener('keyup', handlerUp);
    item.addEventListener('keydown', handlerDown);
  });
}

},{}],25:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
function ToggleBlock() {
  var that = this;
  var bringStarted = void 0;
  var bringEndsRight = void 0;
  var bringEndsLeft = void 0;
  var settings = void 0;

  var defaultSet = {
    display: 'block',
    time: 200,
    callBack: false
  };

  var initAction = function initAction(block, set) {
    that.block = block;
    settings = extendObject(defaultSet, set || {});
    function extendObject(a, b) {
      var c = {};
      var key = void 0;
      for (key in a) {
        if (a.hasOwnProperty(key)) {
          c[key] = key in b ? b[key] : a[key];
        }
      }
      return c;
    }
  };

  var calcTimes = function calcTimes() {
    bringStarted = Date.now();
    bringEndsRight = bringStarted + settings.time;
    bringEndsLeft = bringStarted + settings.time;
  };

  that.slideLeft = function (block, set) {
    initAction(block, set);

    calcTimes();

    var bringSlideLeft = function bringSlideLeft() {
      var dt = void 0,
          t = void 0,
          temp = void 0;

      t = Date.now();

      block.classList.add('start-action');

      if (t <= bringEndsLeft) {
        dt = t - bringStarted;
        temp = EasingFunctions.easeInQuad(dt / settings.time);

        block.style.width = block.scrollWidth - Math.floor(block.scrollWidth * temp) + 'px';

        window.requestAnimationFrame(bringSlideLeft);
      } else {
        block.style.width = '0';
        block.style.display = 'none';
        block.classList.remove('start-action');
        block.classList.remove('block-open');

        if (settings.callBack.slideLeft && typeof settings.callBack.slideLeft === 'function') {
          settings.callBack.slideLeft(block);
        }
      }
    };

    if (that.block.offsetWidth !== 0) {
      window.requestAnimationFrame(bringSlideLeft);
    }
  };

  that.slideRight = function (block, set) {
    initAction(block, set);

    var bringSlideRight = function bringSlideRight() {
      var dt = void 0,
          t = void 0,
          temp = void 0;

      t = Date.now();

      if (t <= bringEndsRight) {
        dt = t - bringStarted;
        temp = EasingFunctions.easeInQuad(dt / settings.time);

        block.style.width = Math.floor(block.scrollWidth * temp) + 'px';

        window.requestAnimationFrame(bringSlideRight);
      } else {
        block.style.width = 'auto';
        block.classList.remove('start-action');
        block.classList.add('block-open');

        if (settings.callBack.slideRight && typeof settings.callBack.slideRight === 'function') {
          settings.callBack.slideRight(block);
        }
      }
    };

    if (that.block.offsetWidth === 0) {
      calcTimes();

      if (settings.display.indexOf('flex') !== -1) {
        block.style.display = 'webkit-' + settings.display;
      }

      block.style.display = settings.display;
      block.classList.add('start-action');

      window.requestAnimationFrame(bringSlideRight);
    }
  };

  that.slideToggleWidth = function (block, set) {
    if (block.classList.contains('block-open')) {
      that.slideLeft(block, set);
    } else {
      that.slideRight(block, set);
    }
  };

  that.slideUp = function (block, set) {
    initAction(block, set);

    calcTimes();

    var bringSlideUp = function bringSlideUp() {
      var dt = void 0,
          t = void 0,
          temp = void 0;

      t = Date.now();

      block.classList.add('start-action');

      if (t <= bringEndsLeft) {
        dt = t - bringStarted;
        temp = EasingFunctions.easeInQuad(dt / settings.time);

        block.style.height = block.scrollHeight - Math.floor(block.scrollHeight * temp) + 'px';

        window.requestAnimationFrame(bringSlideUp);
      } else {
        block.style.height = '0';
        block.style.display = 'none';
        block.classList.remove('start-action');
        block.classList.remove('block-open');

        if (settings.callBack.slideUp && typeof settings.callBack.slideUp === 'function') {
          settings.callBack.slideUp(block);
        }
      }
    };

    if (that.block.offsetHeight !== 0) {
      window.requestAnimationFrame(bringSlideUp);
    }
  };

  that.slideDown = function (block, set) {
    initAction(block, set);

    var bringSlideDown = function bringSlideDown() {
      var dt = void 0,
          t = void 0,
          temp = void 0;

      t = Date.now();

      if (t <= bringEndsRight) {
        dt = t - bringStarted;
        temp = EasingFunctions.easeInQuad(dt / settings.time);

        block.style.height = Math.floor(block.scrollHeight * temp) + 'px';

        window.requestAnimationFrame(bringSlideDown);
      } else {
        block.style.height = 'auto';
        block.classList.remove('start-action');
        block.classList.add('block-open');

        if (settings.callBack.slideDown && typeof settings.callBack.slideDown === 'function') {
          settings.callBack.slideDown(block);
        }
      }
    };

    if (that.block.offsetHeight === 0) {
      calcTimes();

      if (settings.display.indexOf('flex') !== -1) {
        block.style.display = 'webkit-' + settings.display;
      }

      block.style.display = settings.display;
      block.classList.add('start-action');

      window.requestAnimationFrame(bringSlideDown);
    }
  };

  that.slideToggleHeight = function (block, set) {
    if (block.classList.contains('block-open')) {
      that.slideUp(block, set);
    } else {
      that.slideDown(block, set);
    }
  };

  var EasingFunctions = {
    // no easing, no acceleration
    linear: function linear(t) {
      return t;
    },
    // accelerating from zero velocity
    easeInQuad: function easeInQuad(t) {
      return t * t;
    },
    // decelerating to zero velocity
    easeOutQuad: function easeOutQuad(t) {
      return t * (2 - t);
    },
    // acceleration until halfway, then deceleration
    easeInOutQuad: function easeInOutQuad(t) {
      return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
    },
    // accelerating from zero velocity
    easeInCubic: function easeInCubic(t) {
      return t * t * t;
    },
    // decelerating to zero velocity
    easeOutCubic: function easeOutCubic(t) {
      return --t * t * t + 1;
    },
    // acceleration until halfway, then deceleration
    easeInOutCubic: function easeInOutCubic(t) {
      return t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1;
    },
    // accelerating from zero velocity
    easeInQuart: function easeInQuart(t) {
      return t * t * t * t;
    },
    // decelerating to zero velocity
    easeOutQuart: function easeOutQuart(t) {
      return 1 - --t * t * t * t;
    },
    // acceleration until halfway, then deceleration
    easeInOutQuart: function easeInOutQuart(t) {
      return t < 0.5 ? 8 * t * t * t * t : 1 - 8 * --t * t * t * t;
    },
    // accelerating from zero velocity
    easeInQuint: function easeInQuint(t) {
      return t * t * t * t * t;
    },
    // decelerating to zero velocity
    easeOutQuint: function easeOutQuint(t) {
      return 1 + --t * t * t * t * t;
    },
    // acceleration until halfway, then deceleration
    easeInOutQuint: function easeInOutQuint(t) {
      return t < 0.5 ? 16 * t * t * t * t * t : 1 + 16 * --t * t * t * t * t;
    }
  };
}

exports.default = new ToggleBlock();

},{}],26:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
function whichTransitionEvent() {
  var t = void 0;
  var el = document.createElement('fakeelement');
  var resTransitionEnd = void 0;

  var transitionsEnd = {
    'OTransition': 'oTransitionEnd',
    'MozTransition': 'transitionend',
    'WebkitTransition': 'webkitTransitionEnd',
    'transition': 'transitionend'
  };

  for (t in transitionsEnd) {
    if (el.style[t] !== undefined) {
      resTransitionEnd = transitionsEnd[t];
    }
  }

  return resTransitionEnd;
}

exports.default = whichTransitionEvent();

},{}],27:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = validate;
function validate(form, set) {
  var inputs = form.querySelectorAll('[data-validation]');
  var submit = form.querySelector('[type=submit]');

  var defaultSet = {
    wrapInputs: 'label',
    submitFunction: null
  };

  var settings = extendObject(defaultSet, set || {});

  var stopSubmit = void 0;

  var len = inputs.length;

  var inputCustomValidation = [];

  // input.validity = {
  //   valid: false, // Поле валидно
  //   customError: false, // Установленно специальное сообщение ошибки
  //   patternMismatch: false, // Значение не удовлетворяет шаблону, установленному в атрибуте pattern
  //   rangeOverflow: false, // Значение превосходит атрибут max
  //   rangeUnderflow: true, // Значение меньше атрибута min
  //   stepMismatch: true, // Значение не соответствует указаному шагу
  //   tooLong: false, // Значение слишком длинное
  //   tooShort: false, // Значение слишком короткое
  //   typeMismatch: false, // Значение не соответствует указаному атрибуту type
  //   valueMissing: false // Отсутствует обязательное значение
  // };

  var CustomValidation = function CustomValidation(input) {
    // Установим пустой массив сообщений об ошибках
    var that = this;
    that.error = false;
    that.input = input;
    that.invalidities = [];

    // Метод, проверяющий валидность
    that.checkValidityCustom = function () {
      var input = that.input;
      var validity = that.input.validity;

      if (validity.patternMismatch) {
        that.addInvalidity('This is the wrong for this field');
      }

      if (validity.rangeOverflow) {
        var max = input.getAttribute('max');
        that.addInvalidity('The maximum value should be ' + max);
      }

      if (validity.rangeUnderflow) {
        var min = input.getAttribute('min');
        that.addInvalidity('The minimum value should be ' + min);
      }

      if (validity.stepMismatch) {
        var step = input.getAttribute('step');
        that.addInvalidity('This number needs to be a multiple of ' + step);
      }

      if (validity.valueMissing) {
        that.addInvalidity('This field is require');
      }

      if (!validity.valueMissing && validity.typeMismatch) {
        that.addInvalidity('Check the entered field');
      }
    };

    // Добавляем сообщение об ошибке в массив ошибок
    that.addInvalidity = function (message) {
      that.invalidities.push(message);
    };

    // Получаем общий текст сообщений об ошибках
    that.getInvalidities = function () {
      return this.invalidities.join('. \n');
    };

    that.getInvaliditiesForHTML = function () {
      return this.invalidities.join('. <br>');
    };

    that.removeInvaliditiesForHTML = function () {
      var message = this.input.nextElementSibling;
      if (message && message.classList.contains('error-message')) {
        message.remove();
      }
    };

    that.addClassForInput = function () {
      this.input.parentNode.classList.add('error');
    };

    that.removeClassForInput = function () {
      this.input.parentNode.classList.remove('error');
    };
  };

  for (var i = 0; i < len; i++) {
    inputCustomValidation[i] = new CustomValidation(inputs[i]); // Создадим объект CustomValidation
  }

  var processValid = function processValid(index) {
    var input = inputCustomValidation[index].input;
    inputCustomValidation[index].invalidities = [];
    input.setCustomValidity('');

    var message = input.nextElementSibling;
    if (message && message.classList.contains('error-message')) {
      message.remove();
    }

    // Проверим валидность поля, используя встроенную в JavaScript функцию checkValidity()
    if (input.checkValidity() === false) {
      inputCustomValidation[index].checkValidityCustom(); // Выявим ошибки
      var customValidityMessage = inputCustomValidation[index].getInvalidities(); // Получим все сообщения об ошибках

      input.setCustomValidity(customValidityMessage); // Установим специальное сообщение об ошибке

      inputCustomValidation[index].addClassForInput();

      var customValidityMessageForHTML = inputCustomValidation[index].getInvaliditiesForHTML();
      input.insertAdjacentHTML('afterend', '<p class="error-message">' + customValidityMessageForHTML + '</p>');

      inputCustomValidation[index].error = true;

      stopSubmit = true;
    } else {
      if (inputCustomValidation[index].error) {
        input.setCustomValidity('');

        inputCustomValidation[index].invalidities = [];

        inputCustomValidation[index].removeClassForInput();

        inputCustomValidation[index].removeInvaliditiesForHTML();

        inputCustomValidation[index].error = false;
      }
    }
  };

  for (var _i = 0; _i < len; _i++) {
    var input = inputs[_i];

    input.addEventListener('keyup', processValid.bind(input, _i));
    input.addEventListener('change', processValid.bind(input, _i));
  }

  // Добавляем обработчик клика на кнопку отправки формы
  submit.addEventListener('click', function (e) {
    stopSubmit = false;

    // Пройдёмся по всем полям
    for (var _i2 = 0; _i2 < len; _i2++) {
      processValid(_i2);
    }

    if (stopSubmit) {
      e.preventDefault();
    } else {
      if (settings.submitFunction && typeof settings.submitFunction === 'function') {
        settings.submitFunction(e, form);
      }
    }
  });

  function extendObject(a, b) {
    var c = {};
    var key = void 0;
    for (key in a) {
      if (a.hasOwnProperty(key)) {
        c[key] = key in b ? b[key] : a[key];
      }
    }
    return c;
  }
}

},{}]},{},[2]);
